export function formatCurrency(value, decimals) {
  return new Intl.NumberFormat(typeof(window) == 'object' ? window.pluginConfig?.locale || 'en' : 'en', {
    style: 'currency',
    currency: typeof(window) == 'object' ? window.pluginConfig?.currencyCode || 'USD' : 'USD',
    maximumFractionDigits: decimals || 0
  }).format(parseFloat(value));
}

export function formatNumber(value, decimals) {
  return new Intl.NumberFormat(typeof(window) == 'object' ? window.pluginConfig?.locale || 'en' : 'en', {
    maximumFractionDigits: decimals || 0
  }).format(parseFloat(value));
}

export function formatString(str, params) {
  let s = str
  Object.keys(params).map(k => {
    s = s.replaceAll("[" + k + "]", params[k])
  })
  return s
}