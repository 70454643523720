import { createContext, useContext, useState } from "react";

const UserContext = createContext();

export function UserProvider({children}) {

    const [user, setUser] = useState(null);
    const [groupIds, setGroupIds] = useState([]);

    const getGroupChannelIds = () => {
        return groupIds.map(id => {
            return "g--" + id
        })
    }
		
    return (
        <UserContext.Provider value={{ user, setUser, groupIds, setGroupIds, getGroupChannelIds }}>
            {children}
        </UserContext.Provider>
    )
}

export function useUserContext() {
    return useContext(UserContext)
}
export function useCurrentUser() {
    return useContext(UserContext)?.user
}
