import moment from "moment";
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import { APPEARANCE, DENT, HAZARD, KEY, MAINT, PROTECT, SERVICE, TIRE, WARRANTY, WINDSHIELD } from "@/components/Icons"
import { formatNumber } from "@/plugins/Formatters";

export const PROGRESS_PRODUCTS = 10
export const PROGRESS_CHECKOUT = 30
export const PROGRESS_CONTACT = 70
export const PROGRESS_DOWN_PAYMENT = 85
export const PROGRESS_CONTRACT = 100

export const PROGRESS_FILTERS = [
  { id: PROGRESS_PRODUCTS, label: "Products Viewed Only" },
  { id: PROGRESS_CONTACT + "", label: "Submitted Contact" },
  { id: PROGRESS_CHECKOUT + "," + PROGRESS_CONTACT, label: "Intended to Purchase" },
  { id: PROGRESS_DOWN_PAYMENT + "", label: "Paid Down & Not Completed" },
  { id: PROGRESS_DOWN_PAYMENT + "," + PROGRESS_CONTRACT, label: "Paid Down Payment" },
  { id: PROGRESS_CONTRACT + "", label: "Purchased" },
]

export const US_STATES = [
  "", "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MS",
  "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WA", "WI", "WV", "WY"
]

export const CATEGORIES = [
  { id: "vsc", icon: SERVICE, label: "Vehicle Service Contract" },
  { id: "wheel", icon: TIRE, label: "Tire & Wheel Protection" },
  { id: "maintenance", icon: MAINT, label: "Maintenance" },
  { id: "gap", icon: PROTECT, label: "GAP Protection" },
  { id: "paint", icon: DENT, label: "Paint & Dent Repair" },
  { id: "protection", icon: APPEARANCE, label: "Appearance Protection" },
  { id: "appearance", icon: APPEARANCE, label: "Appearance Protection"},
  { id: "tire", icon: TIRE, label: "Tire Protection" },
  { id: "hazard", icon: HAZARD, label: "Roadside Hazard Protection" },
  { id: "key", icon: KEY, label: "Key Replacement" },
  { id: "warranty", icon: WARRANTY, label: "Extended Warranty" },
  { id: "other", icon: PROTECT, label: "Other" },
  { id: "theft", icon: PROTECT, label: "Theft Protection" },
  { id: "windshield", icon: WINDSHIELD, label: "Windshield Protection" },
]

export const CATEGORY_MAP = CATEGORIES.reduce((hash, cat) => {
  hash[cat.id] = cat;
  return hash;
}, {})

export function planPrice(plan, original) {
  let fullPrice = original ? plan.original_price : plan.price
  plan.surcharges?.map(s => {
    if (s.selected) {
      fullPrice += parseFloat(s.price)
    }
  })
  return fullPrice
}

export function formatDate(date, format) {
  if (format) {
    return moment(date).format(format)
  } else {
    return moment(date).format("MM/DD/YYYY")
  }
}

export async function readCsv(file, readString) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onload = function (e) {
      const fileContent = e.target.result;
      readString(fileContent, {
        worker: true,
        complete: (results) => {
          const headers = results.data[0]
          let list = []
          results.data.map((row,index) => {
            if (index > 0) {
              let itemData = {}
              headers.map((h,i) => {
                itemData[h] = row[i]
              })
              list.push(itemData)
            }
          })
          resolve({ headers: headers, data: list })
        },
      });
    };
    reader.readAsText(file);
  })
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text)
  window.dispatchEvent(new CustomEvent("show_flash", { detail: "Copied to clipboard." }))
}

export const isValidEmail = (email) => {
  let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const isValidZip = (zipCode) => {
  const regex = /^\d{5}$/;
  return regex.test(zipCode)
}

export const isValidPhone = (phone) => {
  const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return regex.test(phone)
}
export const isOnlyNumbers = (value) => {
  const regex = /^\d+$/;
  return regex.test(value)
}
export const progressStyle = (value) => {
  if (value > PROGRESS_DOWN_PAYMENT) {
    return { backgroundColor: 'var(--colorGreen)', width: value + '%' }
  } else if (value > PROGRESS_CHECKOUT) {
    return { backgroundColor: 'var(--colorOrange)', width: value + '%' }
  } else if (value > PROGRESS_PRODUCTS) {
    return { backgroundColor: 'var(--colorBlue)', width: value + '%' }
  } else {
    return { backgroundColor: 'var(--colorRed)', width: value + '%' }
  }
}

export const syncFilters = (key, values) => {
  const cookieKey = "ffi_flt_" + key
  const currValues = JSON.parse(getCookie(cookieKey) || "{}")
  if (values) {
    setCookie("ffi_flt_" + key, JSON.stringify(Object.assign({}, currValues, values)))
    return Object.assign({}, currValues, values)
  } else {
    return currValues
  }
}

export function replacePlaceholders(str, options) {
  // [v_vin], [v_year], [v_make], [v_model], [v_trim], [v_mileage], [zip], [c_fn], [c_ln], [c_n], [client]
  str = str.replaceAll("[v_vin]", options.vehicle?.vin)
  str = str.replaceAll("[v_year]", options.vehicle?.year)
  str = str.replaceAll("[v_make]", options.vehicle?.make_name)
  str = str.replaceAll("[v_model]", options.vehicle?.model_name)
  str = str.replaceAll("[v_trim]", options.vehicle?.trim_name)
  str = str.replaceAll("[v_mileage]", formatNumber(options.vehicle?.mileage))
  str = str.replaceAll("[client]", options.config?.display_name || options.config?.name)
  str = str.replaceAll("[zip]", options.vehicle?.zip_code)
  str = str.replaceAll("[c_fn]", options.customer?.first_name)
  str = str.replaceAll("[c_ln]", options.customer?.last_name)
  str = str.replaceAll("[c_n]", options.customer?.name)
  str = str.replaceAll("[company]", options.config?.name)
  return str
}
